import * as React from "react";

import { PrefetchedContent } from "src/components/prefetch";
import { UnauthAppContent } from "../components/AppContent";
import { CrisisBanner } from "../components/Banner";
import { PageManifestCrisisBanner } from "../components/Banner/CrisisBanner";
import Breadcrumbs, { getBreadcrumbsContent } from "../components/Breadcrumbs";
import RootWrapper from "../components/RootWrapper";
import { SuppressAndroidInstallPrompt } from "../components/SuppressAndroidInstallPrompt";

//TODO: Type this exactly to match what tal-core passes on.
export interface AppProps {
  children?: React.ReactNode;
  showNativeAppAd?: boolean;
  setupFonts?: boolean;
  showHeader?: boolean | "unauth" | "scoped";
  prefetchContent?: Record<string, PrefetchedContent>;
  crisisBanner?: PageManifestCrisisBanner;
}

export const CMSHeader = ({
  showNativeAppAd = true,
  showHeader = true,
  setupFonts = false,
  prefetchContent = {},
  crisisBanner = "top",
}: AppProps) => {
  const breadcrumbsContent = getBreadcrumbsContent(prefetchContent);

  return (
    <RootWrapper setupFonts={setupFonts}>
      <div>
        {crisisBanner === "top" && <CrisisBanner position={crisisBanner} />}
        {!showNativeAppAd && <SuppressAndroidInstallPrompt />}
        <UnauthAppContent
          showHeader={showHeader}
          title=""
          actionBar={{ hidden: true }}
          crisisBanner={crisisBanner}
        />
        {crisisBanner === "float" && <CrisisBanner position={crisisBanner} />}
        {breadcrumbsContent && <Breadcrumbs data={breadcrumbsContent} />}
      </div>
    </RootWrapper>
  );
};
